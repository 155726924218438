<template>
  <section class="atencion-virtual">
    <!-- <div class="atencion-virtual__background">
      <h2 class="atencion-virtual__title">Atención Virtual para Argentinos en el Extranjero</h2>
      <a target="_blank" rel="noopener" href="https://wa.me/541159584020?text=Hola%20buenos%20d%C3%ADas%20Susana%21%0A%0AMe%20gustaria%20agendar%20una%20consulta%20psicol%C3%B3gica"><button class="button_style_1">Agendar Consulta</button></a>
    </div> -->
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
  @import "./AtencionOnline.scss";
</style>
