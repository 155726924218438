<template>
  <spline-viewer class="splineContainer" url="https://prod.spline.design/kdQMMnpWqo1XzKm5/scene.splinecode"></spline-viewer>
</template>
  
<script setup>

import { onMounted } from 'vue';
  
  function loadScript() {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://unpkg.com/@splinetool/viewer@0.9.489/build/spline-viewer.js';
    script.onload = onScriptLoaded;
    document.body.appendChild(script);
  }

function onScriptLoaded() {
  var splineViewer = document.querySelector('spline-viewer');
  if (splineViewer) {
    var shadowRoot = splineViewer.shadowRoot;
    var logo = shadowRoot.querySelector('#logo');
    if (logo) {
      logo.remove();
    }
  }
}

onMounted(() => {
  loadScript();
});
</script>
  
<style scoped>
  /* Add your component-specific styles here */
</style>
  