<template>
    <header>
        <BannerHomepage/>
    </header>
    <main>
        <AboutElement/>
        <AtencionOnline/>
        <FeaturedBlogs/>
    </main>
    <footer>
      <Footer/>
    </footer>
</template>

<script>
import AboutElement from '@/components/AboutElement.vue';
import FeaturedBlogs from '@/components/FeaturedBlogs.vue';
import BannerHomepage from '@/components/BannerHomepage.vue';
import Footer from '@/components/Footer.vue';
import AtencionOnline from '@/components/AtencionOnline/AtencionOnline.vue';

export default {
  components: {
        AboutElement,
        FeaturedBlogs,
        BannerHomepage,
        Footer,
        AtencionOnline,
    }
}
</script>

<style scoped>

.footerContainer{
    background-color: #FFF;
}

</style>